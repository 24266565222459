const searchCategories = [
  'All',
  'CLS',
  'IXPs',
  'Cables',
  'Terrestrial',
  'Facilities',
  'Connections',
  'Organizations'
]
export default searchCategories
